<template>
<div id="itManager">
    <section class="hero-section pt-100 background-img" >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6 col-lg-6">
                    <div class="hero-content-left text-white ">
                        <!--<span class="text-uppercase h6">Customer First Priority</span>-->
                        <h1 class="text-white font-weight-bold">I vantaggi per l’IT Manager</h1>
                        <p class="lead">Gestisci con facilità gli asset IT della tua azienda grazie al Device as a Service di Noltech.</p>

                        <button class="configuration-btn" @click="selectDevice(selectedDevice)">Configura i tuoi device</button>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="hero-animation-img">
                        <img src="img/viaggiatore1.png" alt="La soluzione DaaS di Noltech per gli IT Manager" class="img-fluid">
                    </div>
                </div>
            </div>
            <!--counter start-->
            <!--<div class="row">
                <ul class="list-inline counter-wrap">
                    <li class="list-inline-item">
                        <div class="single-counter text-center">
                            <span>2350</span>
                            <h6>?????????????????????</h6>
                        </div>
                    </li>
                    <li class="list-inline-item">
                        <div class="single-counter text-center">
                            <span>2150</span>
                            <h6>?????????????????????</h6>
                        </div>
                    </li>
                    <li class="list-inline-item">
                        <div class="single-counter text-center">
                            <span>2350</span>
                            <h6>?????????????????????</h6>
                        </div>
                    </li>
                    <li class="list-inline-item">
                        <div class="single-counter text-center">
                            <span>2250</span>
                            <h6>?????????????????????</h6>
                        </div>
                    </li>
                </ul>
            </div>-->
            <!--counter end-->
        </div>
    </section>



    <section class="promo-section mt-5 ">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                        <div class="flip-div">
                            <div class="flip-main">
                                <div class="front">
                                    <div class=" single-promo-1">
                                        <div class="card-body text-center pb-2" style="min-height: 200px;">
                                            <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                            <h4 class="card-title numcard">5.550</h4>
                                            <p class="card-text">
                                                Device gestiti *
                                            </p>
                                            <p>
                                                (Elmec 2021)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="back">
                                    <div class="single-promo-1" style="min-height: 200px">
                                        <div class="card-body text-center">
                                            <p class="card-text">* L’esperienza non si improssiva. Siamo attivi nel settore da oltre 50 anni.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 200px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">+80%</h4>
                                        <p class="card-text">
                                            Più veloci nel  configurare un dispositivo B2B*
                                        </p>
                                        <p>
                                            (Benchmark Elmec 2021)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 200px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Grazie alle competenze dei nostri tecnici certificati, riceverai i tuoi dispositivi già configurati in tempi record.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 200px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">71%</h4>
                                        <p class="card-text">
                                            Professionisti IT che segnalano un aumento degli attacchi nel 2020*

                                            <br>(VMware 2021)
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 200px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Le infezioni ai pc sono raddoppiate a causa dello smart working: Noltech ti offre un sistema AV avanzato in grado di prevenire attacchi sofisticati.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 200px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">10 mln €</h4>
                                        <p class="card-text">
                                            Valore delle parti di ricambio a magazzino*
                                        </p>
                                        <p>
                                            (Elmec 2021)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 200px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Riduciamo drasticamente le tempistiche legate alla manutenzione dei device grazie alla disponibilità di parti di ricambio originali</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="pt-150">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-8">
                    <div class="section-heading  mb-5">
                        <h2>Gestione semplificata dei device</h2>
                        <p class="lead">
                            Quanti sono e quali sono i dispositivi della mia azienda? A chi sono stati assegnati? Funzionano tutti correttamente? Come faccio a gestirne la manutenzione garantendo l’operatività dei dipendenti? In Noltech sappiamo come aiutarti. L’offerta Device as a Service di Noltech è stata pensata per supportare gli IT Manager nelle attività di asset management. Sei pronto a partire insieme a noi?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="promo-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Le caratteristiche principali del Daas di Noltech</h2>
                        <p class="lead">
                            Siamo il compagno di viaggio ideale: appassionati di tecnologia proprio come te, attenti all’organizzazione e alla sicurezza del nostro percorso insieme e pronti ad adattarci alle tue necessità.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                            <img src="/img/sicuro.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>Sicuro</h5>
                        <p>Cybersecurity inclusa tra i servizi di gestione dei device.</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                           <img src="/img/affidabile.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>Affidabile</h5>
                        <p>50 anni di esperienza e 155 tecnici certificati a tua disposizione.</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                            <img src="/img/sottoControllo.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>Sotto controllo</h5>
                        <p>Asset management a portata di click sulla piattaforma MyElmec.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>




    <section id="noltechStart" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>Scegli Noltech Start</h2>
                        <p>Non basta uno zaino per partire per un viaggio, nella nostra offerta Device as a Service sono compresi dei servizi che ti supporteranno durante il cammino:</p>
                        <div class="single-feature mb-4 mt-5">
                            <div class="icon-box-wrap d-flex align-items-center mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/ultimaGenerazione.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Device di ultima generazione</strong> –  Parti per il tuo viaggio con il giusto equipaggiamento! Scegliamo con te gli ultimi modelli di laptop, tablet e smartphone dei migliori vendor sul mercato. Grazie alla disponibilità nei nostri magazzini, consegniamo in <strong class="font-weight-bold">tempi record</strong>.</p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/staging.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Staging personalizzato</strong> – Ricevi i dispositivi pronti per essere utilizzati. I nostri tecnici sono veloci come un treno: in media la configurazione di un device richiede circa 2 ore, noi invece impieghiamo solo <strong class="font-weight-bold">20 minuti</strong> e lo prepariamo in base alle tue indicazioni. Non ci credi? Ci siamo cronometrati!</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/cyberSicuro.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Cybersecurity integrata</strong> – Gli attacchi ai dispositivi personali sono raddoppiati nell’ultimo anno, per questo difendere i tuoi dati non è mai stato così importante. In Noltech andiamo oltre ai semplici antivirus e ti forniamo un <strong class="font-weight-bold"> servizio di cybersecurity avanzato</strong> integrato nel canone, vai oltre il semplice next generation AV.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/myelmecBlu.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> MyElmec </strong>–  Incluso nel tuo contratto avrai un accesso a MyElmec, lo strumento perfetto per l’<strong class="font-weight-bold"> asset management</strong>. Da un’unica piattaforma potrai monitorare lo status di tutti i dispositivi in tempo reale, lo storico del nostro viaggio e anche i dettagli amministrativi e contrattuali.</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="about-content-right">
                        <img src="img/zainoIT.svg" alt="about us" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>





    <section id="personalizzaOfferta" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-5">
                    <div class="about-content-right">
                        <img src="img/mappa.svg" alt=" I servizi di gestione dei dispositivi per l'IT Manager" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>Personalizza la tua offerta</h2>
                        <p>Puoi ampliare l’offerta Noltech Start con:</p>
                        <div class="single-feature mb-4 mt-5">
                            <div class="icon-box-wrap d-flex align-items-center mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/smartRollout.svg" alt="Smart Rollout" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Smart Rollout </strong>– Ti trovi a gestire un parco IT datato o particolarmente eterogeneo e vorresti rinnovarlo senza interruzioni? Ci pensiamo noi! I nostri tecnici si occuperanno della <strong class="font-weight-bold"> sostituzione onsite dei vecchi device</strong>, in meno di 20 minuti i tuoi utenti riceveranno un nuovo dispositivo perfettamente configurato. Veloce come una pausa caffè!</p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/wiping.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Blocco remoto e wiping</strong> – La sicurezza delle informazioni è una delle nostre priorità, per questo puoi richiedere il servizio per bloccare da remoto i tuoi device. In più, una volta terminato il contratto, ci occupiamo della <strong class="font-weight-bold"> cancellazione certificata dei dati</strong> da tutti i dispositivi.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/ipergaranzia.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Ipergaranzia Elmec </strong> – Con il Device as a Service andiamo oltre al supporto standard dei produttori e ti offriamo servizi di manutenzione  <strong class="font-weight-bold"> in tutta Europa</strong>. Ritireremo gli hardware da riparare e grazie alla disponibilità di device sostitutivi pre-configurati garantiamo l’operatività dei tuoi utenti.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/office365.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Licenze Office 365 </strong>– Se in azienda utilizzate il pacchetto Office, pensiamo noi a gestire le tue licenze a partire dagli aspetti contrattuali con Microsoft fino al deployment tecnico su tutti i tuoi device.</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container"><div class="row  justify-content-center ptb-60"><a @click="richiediPreventivo" class="btn solid-btn">Configura i tuoi device</a></div></div>
        </div>
    </section>



    <div id="features" class="feature-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <!--<span class="text-uppercase color-secondary sub-title">Best features</span>-->
                        <h2>Ti semplifichiamo la vita</h2>
                        <p>Sappiamo quali sono le attività che riempiono le tue giornate, siamo dei tecnici proprio come te. Per questo vorremmo rendere la tua quotidianità lavorativa più semplice. </p>

                    </div>
                </div>
            </div>

            <!--feature new style start-->
            <div class="row row-grid align-items-center">
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-1 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Assistenza tecnica hardware</h5>
                            <p class="mb-0">  Non dovrai più preoccuparti della manutenzione degli hardware: ripariamo noi i tuoi dispositivi e ti forniamo dei device sostituitivi pre-configurati.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-2 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Asset management </h5>
                            <p class="mb-0">Dimenticati dei file Excel incompleti o non aggiornati: trova tutte le informazioni relative ai dispositivi della tua azienda dalla piattaforma MyElmec.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-3 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Stop alle attività time consuming </h5>
                            <p class="mb-0">Ci occupiamo noi di tutte le incombenze più operative, così avrai più tempo da dedicare alla pianificazione della strategia IT. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="position-relative" style="z-index: 10;">
                        <img alt=" I vantaggi DaaS per l'IT Manager" src="img/itManager.svg" class="img-center img-fluid">
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-4 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Flessibilità </h5>
                            <p class="mb-0">Potrai scegliere diversi modelli di dispositivi, stabilire la durata della collaborazione, aggiungere nuove utenze ogni volta che vuoi e riconsegnare i device inutilizzati, tutto in un unico contratto!</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-5 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Fast Roll-Out </h5>
                            <p class="mb-0">Non dovrai più spostarti per le sedi dell’azienda per gestire il rinnovo del parco IT, i nostri tecnici penseranno alla sostituzione dei vecchi dispositivi direttamente on-site.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-6 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Continuità di servizio</h5>
                            <p class="mb-0">Siamo il tuo unico compagno di viaggio da prima della partenza, con la parte di configurazione, fino al termine del cammino, con lo smaltimento dei device.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--feature new style end-->
        </div>
    </div>





    <section id="tabella" class="package-section gray-light-bg ptb-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="section-heading text-center mb-5">
              <h2>Perchè i nostri clienti hanno scelto Noltech </h2>
              <p class="lead">
                  Abbiamo cambiato le regole per creare un modello di Device as a Service che semplifichi la vita dei nostri clienti.
              </p>
                <p class="lead">
                    Mettici alla prova,<strong class="color-primary font-weight-bold"> i nostri contratti sono senza asterischi!</strong>
                </p>
            </div>
          </div>
        </div>
        <table class="table ">
          <thead class="table-info">
          <tr>

            <th><strong class=" font-weight-bold">Servizi più richiesti dalle aziende</strong></th>
            <th style="text-align: center"><strong class=" font-weight-bold">NolTech</strong>​​</th>
            <th style="text-align: center"><strong class=" font-weight-bold">Gli altri</strong></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td> Unico Interlocutore </td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center">

              <img src="/img/close.svg" width="30" alt="check" class="img-fluid">
              <img src="/img/information.svg" width="16px" v-tooltip.right="'I contratti di noleggio coinvolgono spesso due o più società diverse per gli aspetti tecnici ed amministrativi. Chi è il tuo vero interlocutore?'">
            </td>

          </tr>
          <tr>
            <td>Ci ripenso: restituisci la tecnologia a zero spese</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid">
              <img src="/img/information.svg" width="16px" v-tooltip.right="'Fai attenzione alle condizioni di restituzione di uno o più beni a fine contratto: spesso nascondono clausole vincolanti'">
            </td>

          </tr>
          <tr>
            <td>Contratto aperto e trasparente</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img src="/img/information.svg" width="16px"
                                               v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'">
            </td>

          </tr>
          <tr>
            <td>Tacito Rinnovo</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'"
            >
            </td>

          </tr>
          <tr>
            <td>Servizio EU</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Sei un business europeo/multinazionale: ti occorre una fatturazione separabile su più country. Con altre società avrai più contratti e dovrai gestire il “transfer price.'">
            </td>

          </tr>
          <tr>
            <td>Flessibilità</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Se il tuo business è scalabile nel tempo e vuoi aggiungere device di volta in volta, possono garantirti più lotti noleggiati in tempi diversi?'">
            </td>

          </tr>
          <tr>
            <td>Intervento nelle 24 h</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style="margin-right: 14px" src="/img/question-mark.svg" width="30"
                                                alt="check"
                                                class="img-fluid"></td>

          </tr>
          <tr>
            <td>EmbeddedCyberSecurity</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style="margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"></td>

          </tr>
          <tr>
            <td>Competenza Tecnica certificata</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style=" margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"></td>
          </tr>

          </tbody>
        </table>
      </div>
    </section>




</div>
</template>
<script>
    export default {
        name: 'it-manager'
    }
</script>
<style scoped>
</style>



<script>
    import HubspotSignup from '../HubspotForm'
    import {commonMixins} from '../../mixins/common-mixins'
    import Multiselect from '../global/Multiselect'
    import MyInput from '../global/MyInput'

    export default {
        name: 'Home',
        mixins: [commonMixins],
        components: {MyInput, Multiselect, HubspotSignup},
        watch: {},
        data () {
            return {
                selectedDevice: '',
                deviceQuantity: null
            }
        },
        mounted: function () {
            this.goTopPage()
        },
        methods: {
            selectDevice: function (device) {
                this.$router.push({
                    name: 'Configurator',
                    params: {
                        deviceType: device,
                        deviceQuantity: this.deviceQuantity
                    }
                })
            },
            goToRoute: function (route) {
                this.$router.push({
                    name: route,
                })
            }
        }
    }
</script>

<style lang="less">
    #itManager {
        .configuration-btn {
            margin-top: 20px;
            width: 240px;
            height: 40px;
            outline: none;
            background: #F3F7FA 0% 0% no-repeat padding-box;
            border: 1px solid #2C5593;
            border-radius: 25px;
            opacity: 1;
            color: #2C5593;
            font-size: 16px;
            font-weight: bold;
            padding-top: 4px;
        }
        .btn {
            cursor: pointer;
        }

        .card-body img{
            height: 110px !important;
        }
        .flip-div{
            cursor: pointer;
        }
        .flip-div:hover .back,.flip-div.hover .back {
            -webkit-transform: rotateY(0deg);
            -moz-transform: rotateY(0deg);
            -o-transform: rotateY(0deg);
            -ms-transform: rotateY(0deg);
            transform: rotateY(0deg);
        }
        .flip-div:hover .front,.flip-div.hover .front {
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }
        .flip-main{
            -webkit-transition: 1s;
            -webkit-transform-style: preserve-3d;
            -ms-transition: 1s;
            -moz-transition: 1s;
            -moz-transform: perspective(1000px);
            -moz-transform-style: preserve-3d;
            -ms-transform-style: preserve-3d;
            transition: 1s;
            transform-style: preserve-3d;
            position: relative;
        }
        .front{
            position: relative;
            -webkit-transform: rotateY(0deg);
            -ms-transform: rotateY(0deg);
            z-index: 2;
            margin-bottom: 30px;
        }
        .back{
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transform: rotateY(-180deg);
            -moz-transform: rotateY(-180deg);
            -o-transform: rotateY(-180deg);
            -ms-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
            min-height: 200px;
            background-color: #2C5693;
            color: white;
            border-radius: 1rem;

        }
        .back ul li a{
            font-size: 18px;
        }
        .front,.back {
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transition: 1s;
            -webkit-transform-style: preserve-3d;
            -moz-transition: 1s;
            -moz-transform-style: preserve-3d;
            -o-transition: 1s;
            -o-transform-style: preserve-3d;
            -ms-transition: 1s;
            -ms-transform-style: preserve-3d;
            transition: 1s;
            transform-style: preserve-3d;
        }
        .card-flip{
            border: 1px solid #eff2f7;
            border-radius: 1rem;
            box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
        }
        .numcard{
            font-weight: bold;
        }
    }
</style>
